export const styels = {
    title: {
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        transform: "translate(4px, -34px)",
        fontSize: "12px",
        fontWeight: 500,
        margin: "-12px 0px",
        paddingRight: "4px",
    },
    card: {
        marginLeft: "12px",
        marginBottom: "14px",
        height: "90px",
        width: "90px",
        marginLeft: "12px",
        display: "grid",
        gridGap: "12px",
        borderRadius: "6px"
    }
}