export const styles = {
  card: {
    borderRadius: "8px",
    background: "#FFF",
    boxShadow: "0px 0px 9px 0px rgba(0, 0, 0, 0.14)",
    justifyContent: "flex-start",
    marginBottom: "9px",
    marginRight: "16px",
    marginLeft: "16px",
  },
  image: {
    width: "68px",
    height: "74px",
    // padding: "6px",
    borderRadius: "6px",
    cursor: "pointer",
  },
  time: {
    margin: "auto 0px",
    width: "74px",
    padding: "12px",
  },
  title: {
    width: "100%",
    borderRight: "1px solid #D6D6D6",
    flexDirection: "row",
  },
  header: {
    marginRight: "4px",
    marginLeft: "4px",
    padding: "0px 12px",
  },
  tabCss: {
    padding: "6px 10px",
    marginRight: "12px",
    border: "1px solid",
    color: "black",
    textTransform: "capitalize",
    minWidth: "0",
    height: "26px",
    fontSize: "10px",
    textWrap: "nowrap",
    width: "fit-content",
    "&:hover": {
      // backgroundColor: "black",
      // color: "white",
      border: "1px solid",
    },
    borderColor: "#e0e0e0",
    borderRadius: "4px",
    fontWeight: 400,
    height: "auto",
    cursor: "pointer",
    textAlign: "center",
  },
  layerScroll: {
    overflow: "auto",
    padding: "4px 14px",
    paddingBottom: "10px",
    display: "flex",
  },
  session_name_css: {
    "@media only screen and (min-device-width: 320px) and (max-device-width: 1024px)":
      {
        width: "155px",
      },
    "@media only screen and (min-device-width: 425px) and (max-device-width: 1024px)":
      {
        width: "200px",
      },
  },
};
