import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Typography } from "@mui/material";
import { styels } from "./styles";

export default function SessionType({ session, tabValue, listLength, index }) {
  const navigate = useNavigate();
  const handleSessionType = (sessionTypeId, sessionTitle, type_id) => {
    navigate("/sessions", {
      state: {
        sessionTypeId: sessionTypeId,
        tabValue: tabValue,
        sessionTitle: sessionTitle,
        type_id: type_id,
      },
    });
  };
  return (
    <>
      <Card
        sx={{
          backgroundColor: session.color,
          marginRight: listLength - 1 === index ? "16px" : "0px",
          ...styels.card,
        }}
        elevation={0}
        onClick={() =>
          handleSessionType(session.id, session.title, session.type_id)
        }
      >
        <div
          style={{
            margin: "8px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <img
            src={session.image}
            style={{
              // margin: "-1px 31px 19px 38px",
              width: "42px",
            }}
          />
        </div>
        <br />
        {session.title === "Sports Workout" ||
        session.title === "Interval Training" ? (
          session.title.split(" ").map((word, index) => (
            <Typography
              key={index}
              sx={styels.title}
              variant="h5"
              display="block"
              gutterBottom
              style={{ marginBottom: "1px" }}
            >
              {word}
            </Typography>
          ))
        ) : (
          <Typography
            sx={styels.title}
            variant="h5"
            display="block"
            gutterBottom
          >
            {session.title}
          </Typography>
        )}
      </Card>
      <br />
      <br />
    </>
  );
}
