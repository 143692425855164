import { Typography, Stack } from "@mui/material";
import React from "react";
import SessionType from "./SessionType";
import Dance from "../../assets/Dance.png";
import Yoga from "../../assets/Yoga.png";
import Strength from "../../assets/Strength.png";
import Sports from "../../assets/Sports.png";
import Special from "../../assets/Special movements.png";
import CardioStability from "../../assets/Cardio &amp; Stability.png";
import CardioStrength from "../../assets/Cardio &amp; Strength 1.png";
import Zumba from "../../assets/Zumba.png";
import Interval from "../../assets/Interval Training  1.png";
import "./SessionType.css";
export default function SessionTypeList({ tabValue }) {
  const json = [
    {
      id: "1",
      type_id: "8",
      title: "Dance",
      image: Dance,
      color: "#C4F1FF",
    },
    {
      id: "2",
      type_id: "9",
      title: "Yoga",
      image: Yoga,
      color: "#EDE4BE",
    },
    {
      id: "3",
      type_id: "10",
      title: "Strength Training",
      image: Strength,
      color: "#FFD1D1",
    },
    {
      id: "4",
      type_id: "11",
      title: "Sports Workout",
      image: Sports,
      color: "#C1EDDC",
    },

    {
      id: "6",
      type_id: "26",
      title: "Cardio and Stability",
      image: CardioStability,
      color: "#FACBEA",
    },
    {
      id: "7",
      type_id: "27",
      title: `Interval Training`,
      image: Interval,
      color: "#FFE6E6",
    },
    {
      id: "8",
      type_id: "28",
      title: "Cardio & Strength",
      image: CardioStrength,
      color: "#D2E9E9",
    },
    {
      id: "9",
      type_id: "29",
      title: "Zumba",
      image: Zumba,
      color: "#D7E9D2",
    },
    {
      id: "5",
      type_id: "12",
      title: "Special movements",
      image: Special,
      color: "#EFDEFF",
    },
  ];

  return (
    <div
      className="center-align"
      style={{
        marginLeft: "-8px",
        marginBottom: "16px",
        padding: "0px 0px 0px 16px",
      }}
    >
      <Typography
        variant="h5"
        display="block"
        gutterBottom
        sx={{
          fontWeight: 600,
          fontSize: "14px",
          marginLeft: "8px",
          marginBottom: "14px",
        }}
      >
        Our Sessions
      </Typography>

      <div
        className={"srollHiden"}
        style={{ overflow: "auto", marginLeft: "-8px" }}
      >
        <Stack direction="row" sx={{ width: "max-content" }}>
          {json.map((type, index) => (
            <SessionType
              key={index}
              session={type}
              tabValue={tabValue}
              listLength={json?.length}
              index={index}
            />
          ))}
        </Stack>
      </div>
    </div>
  );
}
