import { Button } from "@mui/material";

function ProgramDeepLinkButton(props) {
    return (
        <Button
            variant="outlined"
            color="primary"
            sx={{ width: "136px", textTransform: "capitalize" }}
            onClick={() => {props.close(); window.location.href = "vmax://openProgramList";}}
        >
            Subscribe
        </Button>
    );
}

export default ProgramDeepLinkButton