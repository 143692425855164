import React, { useEffect, useState } from "react";
import { Typography, Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import SessionCard from "./SessionCard";

import LanguageIcon from "@mui/icons-material/Language";
import { useLocation } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Button from "@mui/material/Button";

// import { styled } from "@mui/material/styles";
import { axiosFetch } from "../../Utils/AxiosFetch";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import NoResultFound from "../NoResultFound/NoResultFound";
import { styles } from "./styles";
import AccordionList from "./AccordionList";
export default function SessionList({ tabValue, coachId, setValue }) {
  const isReCordingTab = tabValue === 2;

  const { state } = useLocation();
  const navigate = useNavigate();
  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  let d = new Date();
  d.setDate(d.getDate() - 2);
  let dayBeforeYesterday = days[d.getDay()];

  let format_id = state && state.type_id;

  let tab_value =
    state && state.tabValue !== undefined ? state.tabValue : tabValue;
  let coach_id = state && state.coachId !== undefined ? state.coachId : coachId;

  let formatId = format_id;

  const [sessionList, setSessionList] = useState([]);

  const [openBackDrop, setOpenBackDrop] = React.useState(false);

  const [currentSession, setCurrentSession] = useState("");
  const [currentTabValue, setCurrentTabValue] = useState(tabValue);

  const handleCloseBackDrop = () => {
    setOpenBackDrop(false);
  };

  let [toggleActiveBtn, setToggleActiveBtn] = useState("");

  const getSessionList = (params, toggleVAlue) => {
    setOpenBackDrop(true);
    let parameters;

    setCurrentSession(params);
    setToggleActiveBtn(toggleVAlue);

    if (tab_value === 0) {
      parameters = {
        timezone: "Asia/Kolkata",
        format_id: formatId !== undefined ? formatId : null,
        coach_id: coach_id !== undefined ? coach_id : null,
      };
    } else if (tab_value === 1) {
      parameters = {
        only_booked: true,
        format_id: formatId !== undefined ? formatId : null,
        coach_id: coach_id !== undefined ? coach_id : null,
      };
    } else if (tab_value === 2) {
      parameters = {
        timezone: "Asia/Kolkata",
        format_id: formatId !== undefined ? formatId : null,
        coach_id: coach_id !== undefined ? coach_id : null,
      };
    } else if (tab_value === 3) {
      parameters = {
        timezone: "Asia/Kolkata",
        format_id: formatId !== undefined ? formatId : null,
        coach_id: coach_id !== undefined ? coach_id : null,
      };
    }

    if (params !== undefined) {
      parameters.format_id = parseInt(params);
    }

    let queryString;
    if (tab_value !== undefined) {
      queryString = Object.entries(parameters)
        .filter(([_, value]) => value !== null)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");
    }

    let url;
    // upcoming session
    if (tab_value === 0 && tab_value !== undefined) {
      if (queryString !== undefined) {
        url = `/upcomingProgramSessions?${queryString}`;
      } else {
        url = `/upcomingProgramSessions`;
      }
    }
    // booked session
    else if (tab_value === 1 && tab_value !== undefined) {
      if (queryString !== undefined) {
        url = `/upcomingProgramSessions?${queryString}`;
      }
    }
    // recorded session
    else if (tab_value === 2 && tab_value !== undefined) {
      if (queryString !== undefined) {
        url = `/completedProgramSessions?${queryString}`;
      } else {
        url = `/completedProgramSessions`;
      }
    } else if (tab_value === 3 && tab_value !== undefined) {
      if (queryString !== undefined) {
        url = `/completedProgramSessionsByUser?${queryString}`;
      } else {
        url = `/completedProgramSessions`;
      }
    }

    if (url !== undefined) {
      axiosFetch({
        url: `${url}`,
        method: "get",
      })
        .then((res) => {
          if (res.status === 401) {
            window.location.href = "vmax://login";
          } else if (res.status === 200) {
            setOpenBackDrop(false);
            setSessionList(res.data.body);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  useEffect(() => {
    getSessionList();
    if (state && state.sessionTypeId !== null) {
      setToggleActiveBtn(state.sessionTypeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state?.type_id) setCurrentSession(state?.type_id);
    if (state?.tabValue) setCurrentTabValue(state?.tabValue);
  }, [state]);

  const isSessionListEmpty = (value) => {
    switch (value) {
      case 0:
      case 1:
        return (
          sessionList?.today?.length === 0 &&
          sessionList?.tomorrow?.length === 0 &&
          sessionList?.dayAfterTomorrow?.length === 0
        );
      case 2:
        return (
          sessionList?.today?.length === 0 &&
          sessionList?.yesterday?.length === 0 &&
          sessionList?.dayBeforeYesterday?.length === 0
        );
      case 3:
        return sessionList?.completed?.length === 0;

      case "9":
      case "8":
      case "11":
      case "10":
      case "26":
      case "27":
      case "28":
      case "29":
      case "12": {
        if (currentTabValue === 2) {
          return (
            sessionList?.today?.length === 0 &&
            sessionList?.yesterday?.length === 0 &&
            sessionList?.dayBeforeYesterday?.length === 0
          );
        } else if (currentTabValue === 3) {
          return sessionList?.length === 0;
        }

        return (
          sessionList?.today?.length === 0 &&
          sessionList?.tomorrow?.length === 0 &&
          sessionList?.dayAfterTomorrow?.length === 0
        );
      }
      default:
        return false;
    }
  };

  return (
    <Container sx={{ overflow: "hidden", padding: "0px" }}>
      <div style={{ overflow: "hidden" }}>
        {state && state.sessionTypeId && state.sessionTypeId !== null && (
          <div>
            <Button
              variant="text"
              startIcon={<ArrowBackIosIcon style={{ fontSize: "18px" }} />}
              display="block"
              gutterBottom
              sx={{
                fontWeight: 600,
                color: "black",
                textTransform: "capitalize",
                fontSize: "16px",
                margin: "16px",
              }}
              onClick={() => navigate(-1)}
            >
              Our Sessions
            </Button>

            <Grid className={"srollHiden"} sx={styles.layerScroll}>
              <div>
                <Typography
                  // variant={""}
                  sx={{
                    ...styles.tabCss,
                    borderColor: toggleActiveBtn === "2" ? "#000" : "#e0e0e0",
                    fontWeight: toggleActiveBtn === "2" ? "600" : "400",
                  }}
                  onClick={() => getSessionList("9", "2")}
                >
                  Yoga
                </Typography>
              </div>

              <div>
                <Typography
                  // variant="subtitle1"
                  sx={{
                    ...styles.tabCss,
                    borderColor: toggleActiveBtn === "1" ? "#000" : "#e0e0e0",
                    fontWeight: toggleActiveBtn === "1" ? 600 : 400,
                  }}
                  onClick={() => getSessionList("8", "1")}
                >
                  Dance
                </Typography>
              </div>
              <div>
                <Typography
                  // variant="subtitle1"
                  sx={{
                    ...styles.tabCss,
                    width: "80px",
                    borderColor: toggleActiveBtn === "4" ? "#000" : "#e0e0e0",
                    fontWeight: toggleActiveBtn === "4" ? 600 : 400,
                  }}
                  onClick={() => getSessionList("11", "4")}
                >
                  Sports Workout
                </Typography>
              </div>

              <div>
                <Typography
                  variant={"h6"}
                  sx={{
                    ...styles.tabCss,
                    width: "120px",
                    borderColor: toggleActiveBtn === "3" ? "#000" : "#e0e0e0",
                    fontWeight: toggleActiveBtn === "3" ? 600 : 400,
                  }}
                  onClick={() => getSessionList("10", "3")}
                >
                  Strength Training
                </Typography>
              </div>
              <div>
                <Typography
                  variant={"h6"}
                  sx={{
                    ...styles.tabCss,
                    width: "120px",
                    borderColor: toggleActiveBtn === "6" ? "#000" : "#e0e0e0",
                    fontWeight: toggleActiveBtn === "6" ? 600 : 400,
                  }}
                  onClick={() => getSessionList("26", "6")}
                >
                  Cardio and Stability
                </Typography>
              </div>
              <div>
                <Typography
                  variant={"h6"}
                  sx={{
                    ...styles.tabCss,
                    width: "120px",
                    borderColor: toggleActiveBtn === "7" ? "#000" : "#e0e0e0",
                    fontWeight: toggleActiveBtn === "7" ? 600 : 400,
                  }}
                  onClick={() => getSessionList("27", "7")}
                >
                  Interval & Training
                </Typography>
              </div>
              <div>
                <Typography
                  variant={"h6"}
                  sx={{
                    ...styles.tabCss,
                    width: "120px",
                    borderColor: toggleActiveBtn === "8" ? "#000" : "#e0e0e0",
                    fontWeight: toggleActiveBtn === "8" ? 600 : 400,
                  }}
                  onClick={() => getSessionList("28", "8")}
                >
                  Cardio & Strength
                </Typography>
              </div>
              <div>
                <Typography
                  variant={"h6"}
                  sx={{
                    ...styles.tabCss,
                    width: "120px",
                    borderColor: toggleActiveBtn === "9" ? "#000" : "#e0e0e0",
                    fontWeight: toggleActiveBtn === "9" ? 600 : 400,
                  }}
                  onClick={() => getSessionList("29", "9")}
                >
                  Zumba
                </Typography>
              </div>
              <div>
                <Typography
                  // variant="subtitle1"
                  sx={{
                    ...styles.tabCss,
                    width: "90px",
                    borderColor: toggleActiveBtn === "5" ? "#000" : "#e0e0e0",
                    fontWeight: toggleActiveBtn === "5" ? 600 : 400,
                  }}
                  onClick={() => getSessionList("12", "5")}
                >
                  Special Moments
                </Typography>
              </div>
            </Grid>
            <br />
          </div>
        )}

        {isSessionListEmpty(tabValue) && <NoResultFound />}
        {isSessionListEmpty(currentSession) && <NoResultFound />}
        {console.log(
          sessionList,
          dayBeforeYesterday,
          sessionList[dayBeforeYesterday],
          "ttt"
        )}
        {sessionList &&
          sessionList.dayBeforeYesterday !== undefined &&
          sessionList.dayBeforeYesterday?.length !== 0 && (
            <AccordionList day={dayBeforeYesterday} showCard={false}>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openBackDrop}
                onClick={handleCloseBackDrop}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              {sessionList.dayBeforeYesterday?.length !== 0 && (
                <Stack direction="column" spacing={2}>
                  {sessionList.dayBeforeYesterday?.length !== 0 &&
                    sessionList.dayBeforeYesterday?.map((session) => (
                      <>
                        <SessionCard
                          setValue={setValue}
                          session={session}
                          tabValue={tabValue}
                          when={dayBeforeYesterday}
                        />
                        <br />
                        <br />
                      </>
                    ))}
                </Stack>
              )}
            </AccordionList>
          )}

        {sessionList &&
          sessionList.yesterday !== undefined &&
          sessionList?.yesterday?.length !== 0 && (
            <AccordionList day="Yesterday" showCard={true}>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openBackDrop}
                onClick={handleCloseBackDrop}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              {sessionList?.yesterday?.length !== 0 && (
                <Stack direction="column" spacing={2}>
                  {sessionList?.yesterday?.length !== 0 &&
                    sessionList?.yesterday?.map((session) => (
                      <>
                        <SessionCard
                          setValue={setValue}
                          session={session}
                          tabValue={tabValue}
                          when="Yesterday"
                        />
                        <br />
                        <br />
                      </>
                    ))}
                </Stack>
              )}
            </AccordionList>
          )}

        {sessionList &&
          sessionList.today !== undefined &&
          sessionList?.today?.length !== 0 && (
            <AccordionList day="Today" showCard={true}>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openBackDrop}
                onClick={handleCloseBackDrop}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              {sessionList?.today?.length !== 0 && (
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="h5"
                    display="block"
                    gutterBottom
                    sx={{
                      fontWeight: 600,
                      fontSize: "14px",
                      marginBottom: "10px",
                      ...styles.header,
                    }}
                  >
                    My Sessions
                  </Typography>

                  {!isReCordingTab ? (
                    <>
                      {sessionList?.today?.some(
                        (session) => session.purchasedSession
                      ) ? (
                        sessionList.today.map((session) =>
                          session.purchasedSession ? (
                            <SessionCard
                              key={session.id}
                              setValue={setValue}
                              session={session}
                              tabValue={tabValue}
                              when="Today"
                            />
                          ) : null
                        )
                      ) : (
                        <Typography
                          style={{ textAlign: "center", fontSize: "12px" }}
                        >
                          No purchased sessions available.
                        </Typography>
                      )}

                      {!isReCordingTab ? (
                        <>
                          <Typography
                            variant="h5"
                            display="block"
                            gutterBottom
                            sx={{
                              fontWeight: 600,
                              fontSize: "14px",
                              marginBottom: "10px",
                              ...styles.header,
                            }}
                          >
                            Other Sessions
                          </Typography>

                          {sessionList?.today?.some(
                            (session) => !session.purchasedSession
                          ) ? (
                            sessionList.today.map((session) =>
                              !session.purchasedSession ? (
                                <SessionCard
                                  key={session.id}
                                  setValue={setValue}
                                  session={session}
                                  tabValue={tabValue}
                                  when="Today"
                                />
                              ) : null
                            )
                          ) : (
                            <Typography
                              style={{ textAlign: "center", fontSize: "12px" }}
                            >
                              No other sessions available.
                            </Typography>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    sessionList.today.map((session) => (
                      <SessionCard
                        key={session.id}
                        setValue={setValue}
                        session={session}
                        tabValue={tabValue}
                        when="Today"
                      />
                    ))
                  )}

                  <br />
                  <br />
                </Stack>
              )}
            </AccordionList>
          )}
        {sessionList &&
          sessionList.tomorrow !== undefined &&
          sessionList?.tomorrow?.length !== 0 && (
            <AccordionList day="Tomorrow" showCard={false}>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openBackDrop}
                onClick={handleCloseBackDrop}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              {sessionList?.tomorrow?.length !== 0 && (
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="h5"
                    display="block"
                    gutterBottom
                    sx={{
                      fontWeight: 600,
                      fontSize: "14px",
                      marginBottom: "10px",
                      ...styles.header,
                    }}
                  >
                    My Sessions
                  </Typography>

                  {sessionList?.tomorrow?.some(
                    (session) => session.purchasedSession
                  ) ? (
                    sessionList.tomorrow.map((session) =>
                      session.purchasedSession ? (
                        <SessionCard
                          key={session.id}
                          setValue={setValue}
                          session={session}
                          tabValue={tabValue}
                          when="Today"
                        />
                      ) : null
                    )
                  ) : (
                    <Typography
                      style={{ textAlign: "center", fontSize: "12px" }}
                    >
                      No purchased sessions available.
                    </Typography>
                  )}

                  {!isReCordingTab && (
                    <>
                      <Typography
                        variant="h5"
                        display="block"
                        gutterBottom
                        sx={{
                          fontWeight: 600,
                          fontSize: "14px",
                          marginBottom: "10px",
                          ...styles.header,
                        }}
                      >
                        Other Sessions
                      </Typography>

                      {sessionList?.tomorrow?.some(
                        (session) => !session.purchasedSession
                      ) ? (
                        sessionList.tomorrow.map((session) =>
                          !session.purchasedSession ? (
                            <SessionCard
                              key={session.id}
                              setValue={setValue}
                              session={session}
                              tabValue={tabValue}
                              when="Today"
                            />
                          ) : null
                        )
                      ) : (
                        <Typography
                          style={{ textAlign: "center", fontSize: "12px" }}
                        >
                          No other sessions available.
                        </Typography>
                      )}
                    </>
                  )}

                  <br />
                  <br />
                </Stack>
              )}
            </AccordionList>
          )}
        {sessionList &&
          sessionList.dayAfterTomorrow !== undefined &&
          sessionList?.dayAfterTomorrow?.length !== 0 && (
            <AccordionList day="Day After Tomorrow" showCard={false}>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openBackDrop}
                onClick={handleCloseBackDrop}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              {sessionList?.dayAfterTomorrow?.length !== 0 && (
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="h5"
                    display="block"
                    gutterBottom
                    sx={{
                      fontWeight: 600,
                      fontSize: "14px",
                      marginBottom: "10px",
                      ...styles.header,
                    }}
                  >
                    My Sessions
                  </Typography>

                  {sessionList?.dayAfterTomorrow?.some(
                    (session) => session.purchasedSession
                  ) ? (
                    sessionList.dayAfterTomorrow.map((session) =>
                      session.purchasedSession ? (
                        <SessionCard
                          key={session.id}
                          setValue={setValue}
                          session={session}
                          tabValue={tabValue}
                          when="Today"
                        />
                      ) : null
                    )
                  ) : (
                    <Typography
                      style={{ textAlign: "center", fontSize: "12px" }}
                    >
                      No purchased sessions available.
                    </Typography>
                  )}

                  {!isReCordingTab && (
                    <>
                      <Typography
                        variant="h5"
                        display="block"
                        gutterBottom
                        sx={{
                          fontWeight: 600,
                          fontSize: "14px",
                          marginBottom: "10px",
                          ...styles.header,
                        }}
                      >
                        Other Sessions
                      </Typography>

                      {sessionList?.dayAfterTomorrow?.some(
                        (session) => !session.purchasedSession
                      ) ? (
                        sessionList.dayAfterTomorrow.map((session) =>
                          !session.purchasedSession ? (
                            <SessionCard
                              key={session.id}
                              setValue={setValue}
                              session={session}
                              tabValue={tabValue}
                              when="Today"
                            />
                          ) : null
                        )
                      ) : (
                        <Typography
                          style={{ textAlign: "center", fontSize: "12px" }}
                        >
                          No other sessions available.
                        </Typography>
                      )}
                    </>
                  )}

                  <br />
                  <br />
                </Stack>
              )}
            </AccordionList>
          )}
        {sessionList &&
          sessionList.completed !== undefined &&
          sessionList?.completed?.length !== 0 && (
            <AccordionList day="Completed Session" showCard={false}>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openBackDrop}
                onClick={handleCloseBackDrop}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              {sessionList?.completed?.length !== 0 && (
                <Stack direction="column" spacing={2}>
                  {sessionList?.completed?.some(
                    (session) => !session.purchasedSession
                  ) ? (
                    sessionList.completed.map((session) =>
                      !session.purchasedSession ? (
                        <SessionCard
                          key={session.id}
                          setValue={setValue}
                          session={session}
                          startDate={session?.start_date}
                          tabValue={tabValue}
                          when="Today"
                        />
                      ) : null
                    )
                  ) : (
                    <Typography
                      style={{ textAlign: "center", fontSize: "12px" }}
                    >
                      No other sessions available.
                    </Typography>
                  )}

                  <br />
                  <br />
                </Stack>
              )}
            </AccordionList>
          )}
      </div>
    </Container>
  );
}
