import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/material";
import "./Navbar.css";
import PropTypes from "prop-types";
import SessionList from "../Sessions/SessionList";
import CoachList from "../Coach/CoachList";
import SessionTypeList from "../SessionType/SessionTypeList";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ padding: "0px 4px" }}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Navbar = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    event.preventDefault();
    // toggleTab(newValue);
    setValue(newValue);
  };

  return (
    <>
      {/* <Card
        sx={{
          width: "fit-content",
          margin: "auto",
        }}
      > */}
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          margin: "18px 16px 24px 16px",
        }}
      >
        <Tabs
          centered
          value={value}
          onChange={handleChange}
          // variant="scrollable"
          scrollButtons={false}
          aria-label="basic tabs example"
          sx={
            {
              // backgroundColor: "#EAEAEA",
              // color: "black",
              // fontWeight: "600",
            }
          }
        >
          <Tab
            {...a11yProps(0)}
            label="Upcoming"
            sx={{
              fontWeight: "600",
              textTransform: "capitalize",
              // color: "black",
            }}
          />
          <Tab
            {...a11yProps(1)}
            label="Booked"
            sx={{
              fontWeight: "600",
              textTransform: "capitalize",
              // color: "black",
            }}
          />
          <Tab
            {...a11yProps(2)}
            label="Recorded"
            sx={{
              fontWeight: "600",
              textTransform: "capitalize",
              // color: "black",
            }}
          />
          <Tab
            {...a11yProps(3)}
            label="Completed"
            sx={{
              fontWeight: "600",
              textTransform: "capitalize",
              // color: "black",
            }}
          />
        </Tabs>
      </Box>
      {/* </Card> */}

      <SessionTypeList tabValue={value} />
      <CoachList tabValue={value} />

      <TabPanel value={value} index={0}>
        <SessionList tabValue={value} setValue={setValue} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SessionList tabValue={value} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <SessionList tabValue={value} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <SessionList tabValue={value} />
      </TabPanel>
    </>
  );
};

export default Navbar;
