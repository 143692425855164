import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { CardContent, CardMedia, Typography } from "@mui/material";
import MaleCoach from "../../assets/maleCoach.png";
import Divider from "@mui/material/Divider";

export default function NewCard() {
  return (
    <>
      <Card sx={{ display: "flex" }}>
        <CardMedia
          component="img"
          sx={{ width: 73 }}
          image={MaleCoach}
          alt="Live from space album cover"
        />
        <Box sx={{ display: "flex", flexDirection: "column", margin: "auto" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography component="div" fontSize="13px">
              <strong>Full Body Beginner Fitness (with weights)</strong>
            </Typography>
            <Typography
              fontSize="10px"
              component="div"
              sx={{ color: "#757575" }}
            >
              With Paramjeet
            </Typography>
            <Typography fontSize="10px" component="div">
              <strong>.Beginner .60 Mins</strong>
            </Typography>
          </CardContent>
        </Box>
        <Box sx={{ marginTop: "18px", marginRight: "15px" }}>
          <Typography component="div" fontSize="10px">
            12 : 30 PM
          </Typography>

          <Button
            variant="outlined"
            sx={{ borderRadius: "20px", fontSize: "6px", height: "17px" }}
            color="error"
          >
            Delete
          </Button>
        </Box>
      </Card>
    </>
  );
}
