export const styles = {
    containerArea: {
        height: "100vh",
    },
    loginArea: {
        background: "#242424"
    },
    LoginTitle: {
        color: "#FFF",
        textAlign: "center",
        fontSize: "48px",
        fontStyle: "normal",
        fontWeight: "500",
        // lineHeight: "56px",
        '@media (max-width: 780px)' : {
            fontSize: "24px"
        }
    },
    headerSpace:{
        marginBottom: "40px",
        marginTop: "40px"
    },
    mobilenumber: {
        width: "320px",

        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#A0A0A0"
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: "12px",
            marginBottom: "30px",
            color: "#A0A0A0",
            '&:hover fieldset': {
              borderColor: "#A0A0A0" // Change this to your desired color for hover
            },
            '&.Mui-focused fieldset': {
              borderColor: "#A0A0A0" // Change this to your desired color for focus
            },
        },
    },
    buttonFiled: {
        width: "260px",
        color: "#000",
        textTransform: "capitalize",
        borderRadius: "8px",
        background: "#FFF",
        boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.30)",
        '&:hover':{
            color: "#000",
            background: "#ccc",
        },
        '&:focus':{
            color: "#000",
            background: "#FFF",
        },
        '&.Mui-disabled':{
            backgroundColor: "#a0a0a0",
        }
    },
    mobileTop: {
        width: "60px",
        marginRight: "12px",
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#A0A0A0"
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: "12px",
            marginBottom: "30px",
            color: "#A0A0A0",
            '&:hover fieldset': {
              borderColor: "#A0A0A0" // Change this to your desired color for hover
            },
            '&.Mui-focused fieldset': {
              borderColor: "#A0A0A0" // Change this to your desired color for focus
            },
        },
    },
    imageAd: {
        borderRadius: "38px",
        background: "#FFF",
        boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.14)",
        padding: "12px",
        margin: "30px",
        height: "88vh",
        width: "390px",
        overflow: "hidden",
    },
    backBtn: {
        marginTop: "12px",
        color: "#fff",
        fontSize: "12px",
        cursor: "pointer",
        textDecoration: "underline",
    }
};