import React, { useEffect } from "react";
import Booked from "../../assets/booked.png";
import Box from "@mui/material/Box";
import { Typography, Button, Container, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import Zoom from "../../assets/zoom.png";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export default function BookedPage() {
  const { state } = useLocation();
  const navigate = useNavigate();
  let platform = localStorage.getItem("platform");
  let platformLink;

  // useEffect(() => {
  //   if (platform !== null) {
  //     if (platform === "android") {
  //       platformLink = "https://play.google.com/store/search?q=zoom&c=apps";
  //     } else if (platform === "ios") {
  //       platformLink =
  //         "https://apps.apple.com/us/app/zoom-cloud-meetings/id546505307";
  //     }
  //   }
  // }, []);

  let { coach_name, start_time, when } = state;

  const openLinkInNewTab = (url) => {
    window.open(url, "_blank");
  };

  const handleClick = () => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const isAndroid = /Android/.test(navigator.userAgent);

    let url = "";
    if (isIOS) {
      url = "https://apps.apple.com/us/app/zoom-cloud-meetings/id546505307";
    } else if (isAndroid) {
      url = "https://play.google.com/store/search?q=zoom&c=apps";
    } else {
      url = "https://zoom.us/download";
    }

    openLinkInNewTab(url);
  };

  return (
    <div>
      <Button
        variant="text"
        startIcon={<ArrowBackIosIcon style={{ fontSize: "18px" }} />}
        display="block"
        gutterBottom
        sx={{
          fontWeight: 600,
          color: "black",
          textTransform: "capitalize",
          fontSize: "16px",
          margin: "16px",
        }}
        onClick={() => {
          navigate(-1);
        }}
      >
        Back
      </Button>
      <Box
        sx={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -39%)",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <img
            src={Booked}
            // style={{ transform: "translate(170px, 10px)" }}
            width="80px"
            height="80px"
          />
        </Grid>

        <Typography
          variant="h6"
          sx={{
            // fontFamily: "Gordita",
            margin: { xs: "15px" },
            fontWeight: 600,
            textAlign: "center",
            marginTop: "14px",
            fontSize: "18px",
          }}
        >
          Your session with {coach_name} has been booked for &nbsp;
          {when} at {start_time}
        </Typography>
        <br />
        <Card
          sx={{
            width: { xs: "320px", sm: "444px" },
            m: 2,
            margin: "16px auto",
            borderRadius: "16px",
            background: "#FFF",
            boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.14)",
          }}
        >
          <Container>
            <Box sx={{ display: "flex" }}>
              <img
                src={Zoom}
                alt="zoom"
                style={{
                  width: "47px",
                  marginTop: "12px",
                  height: "47px",
                }}
              />

              <Typography sx={{ fontSize: "16px", margin: "15px" }}>
                Get Zoom to Access the session
              </Typography>
            </Box>

            <Typography
              sx={{ color: "#757575", fontSize: "14px", marginTop: "12px" }}
            >
              You need to download{" "}
              <strong style={{ color: "black" }}>Zoom</strong> to access your
              live session. We will send you your private links to join the
              session.
            </Typography>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                onClick={handleClick}
                variant="contained"
                sx={{
                  borderRadius: "8px",
                  backgroundColor: "#242424",
                  boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.30)",
                  color: "#fff",
                  textTransform: "Capitalize",
                  justifyContent: "center",
                  alignItems: "center",
                  // transform: "translate(100px, 10px)",
                  fontSize: "12px",
                  marginBottom: "24px",
                  marginTop: "12px",
                  padding: "6px 50px",
                }}
              >
                Download Zoom Now
              </Button>
            </Grid>
          </Container>
        </Card>
      </Box>
    </div>
  );
}
