// PrivateRoute.js
import React from 'react';
import { Navigate, Route } from 'react-router-dom';

const PrivateRoute = ({ path, children, isAuthenticated, ...rest }) => {
  return <>
    {
      isAuthenticated ? children : (
        <Navigate
          to={"/login"}
          state={{ from: path }}
        />
      )
    }
  </>
};

export default PrivateRoute;