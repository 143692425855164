import * as React from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#FAD6D6",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: "#C81F22",
  maxWidth: 400,
}));

export default function NoResultFound() {
  return (
    <Box sx={{ flexGrow: 1, overflow: "hidden", px: 3 }}>
      <Item
        sx={{
          my: 1,
          mx: "auto",
          p: 2,
        }}
      >
        <Stack spacing={2} direction="row" alignItems="center">
          <Typography justifyContent="center" alignContent="center" m="auto">
            No Sessions Found
          </Typography>
        </Stack>
      </Item>
    </Box>
  );
}
