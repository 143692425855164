import axios from "axios";
import { ATLAS_URI } from "./Constants";

const queryParams = new URLSearchParams(window.location.search);
// const token = queryParams.get("token");
// const token = localStorage.getItem("authTocken");

var configHeader = {};

export const axiosFetch = async (configObj) => {
  let token = localStorage.getItem("authTocken");
  // var userDetails =
  //   localStorage.getItem("operator") !== null
  //     ? localStorage.getItem("operator")
  //     : null;

  // var token =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjM4NzQyLCJpYXQiOjE3MDA2MjcwMDEsImV4cCI6MTcwMTIzMTgwMX0.m4mb0WM2XtSaD-9sGV1No2BtGqq_45aA2Eq5OxGXgeE";
  if (token !== undefined) {
    configHeader = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  } else {
    configHeader = {
      Accept: "application/json, text/plain",
      "Content-Type": "application/json",
    };
  }
  const ConfigAxios = axios.create({
    baseURL: ATLAS_URI,
    headers: configHeader,
  });

  const { method, url, requestConfig = {} } = configObj;
  try {
    const res = await ConfigAxios[method.toLowerCase()](url, {
      ...requestConfig,
    });
    return res;
  } catch (err) {
    return err.response;
  }
};
