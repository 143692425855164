import React from "react";
import Card from "@mui/material/Card";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styels } from "./styles";
import EmptyProfile from "../../assets/emptyProfile.jpg";
export default function CoachCard({
  coach,
  tabValue,
  list_coach_length,
  index,
}) {
  const navigate = useNavigate();
  const onClick = (coach) => {
    navigate("/aboutCoach", {
      state: { coachDtls: coach, tabValue: tabValue },
    });
  };
  return (
    <div
      style={{
        width: "84px",
        paddingRight: list_coach_length - 1 === index ? "16px" : "0px",
      }}
    >
      <Card elevation={0} sx={styels.card} onClick={() => onClick(coach)}>
        <img
          src={coach?.imageUrl ? coach?.imageUrl : EmptyProfile}
          alt={coach.firstName}
          style={styels.image}
        />
      </Card>
      <Typography
        variant="h5"
        display="block"
        gutterBottom
        fontSize="10px"
        textAlign="center"
        fontWeight={500}
        sx={{ marginTop: "8px" }}
      >
        {coach.firstName !== null && coach.firstName}

        {/* {coach.firstName !== null && coach.lastName !== null
          ? coach.firstName + " " + coach.lastName
          : coach.firstName !== null
          ? coach.firstName
          : coach.lastName !== null
          ? coach.lastName
          : ""} */}
      </Typography>
    </div>
  );
}
