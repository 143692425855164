import React, { useState } from "react";

import LanguageIcon from "@mui/icons-material/Language";
import { Typography } from "@mui/material";

import { styles } from "./styles";

import { Card } from "@mui/material";

import "../../components/commonStyle.css";

import "./AccordionList.css";
const AccordionList = ({ day, children, showCard }) => {
  const [open, setOpen] = useState(showCard);

  return (
    <div className="history-container">
      <Card sx={{ display: "flex", ...styles.card }}>
        <div className={`history-header`}>
          <div
            className="history-title-container"
            onClick={() => setOpen((pre) => !pre)}
          >
            <div>
              <Typography
                variant="h5"
                display="block"
                gutterBottom
                className="session-day-title"
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  marginBottom: "10px",
                  userSelect: "none",
                }}
              >
                {day}
              </Typography>
            </div>
            <div>
              {/* <span
              style={{
                color: "#757575",
                fontWeight: 100,
                fontSize: "12px",
                marginRight: "20px",
                userSelect: "none",
              }}
            >
              Timezone: Asia/Kolkata
              <LanguageIcon
                style={{
                  transform: "translate(10px, 6px)",
                  width: "18px",
                }}
              />
            </span> */}

              <span className={`expand-icon ${open ? "open" : ""}`}>▼</span>
            </div>
          </div>

          {open && <div className={`history-details`}>{children}</div>}
        </div>
      </Card>
    </div>
  );
};

export default AccordionList;
