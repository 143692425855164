export const styels = {
    card:{
        height: "100px",
        width: "84px",
        borderRadius: "8px"
    },
    image: {
        width: "84px",
        height: "100px",
        objectFit: "cover",
    }
}