import React, { useEffect, useState } from "react";
import { Typography, Stack, CircularProgress, Box, Grid } from "@mui/material";
import CoachCard from "./CoachCard";
import Coach from "../../assets/coach.jpg";
import { axiosFetch } from "../../Utils/AxiosFetch";

import "../commonStyle.css";

export default function CoachList({ tabValue }) {
  const [list_coach, setList_coach] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getCoachList = () => {
    axiosFetch({
      url: "/programSessionCoaches",
      method: "get",
    })
      .then((res) => {
        if (res.status === 401) {
          window.location.href = "vmax://login";
        } else if (res.status === 200) {
          setIsLoading(false);
          setList_coach(res.data.body);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getCoachList();
  }, []);

  return (
    <div className="center-align">
      <Typography
        variant="h5"
        display="block"
        gutterBottom
        sx={{
          fontWeight: 600,
          fontSize: "14px",
          marginBottom: "14px",
          padding: "0px 0px 0px 20px",
        }}
      >
        Meet Our Coaches
      </Typography>
      {isLoading && (
        <Grid container spacing={3} sx={{ width: "100%" }}>
          <Grid
            item
            md={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Grid>
        </Grid>
      )}
      <div
        className={"coaches_container"}
        style={{
          overflow: "auto",
          marginBottom: "16px",
          padding: "0px 0px 0px 20px",
        }}
      >
        <Stack direction="row" spacing={2}>
          {list_coach.length !== 0 &&
            list_coach.map((coach, index) => (
              <CoachCard
                key={index}
                coach={coach}
                tabValue={tabValue}
                list_coach_length={list_coach?.length}
                index={index}
              />
            ))}
        </Stack>
      </div>
    </div>
  );
}

//srollHiden
