import React from "react";
import { Button, Card, CardMedia, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import EmptyProfile from "../../assets/emptyProfile.jpg";
import SessionList from "../Sessions/SessionList";
import { useLocation, useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";

export default function AboutCoach() {
  const { state } = useLocation();

  let { firstName, lastName, imageUrl, about, id, skillImageUrls } =
    state.coachDtls;

  const navigate = useNavigate();

  return (
    <Container sx={{ overflow: "hidden", padding: "0px" }}>
      <div>
        <Button
          variant="text"
          startIcon={
            <ArrowBackIosIcon
              style={{
                fontSize: "18px",
              }}
            />
          }
          display="block"
          gutterBottom
          sx={{
            fontWeight: 600,
            color: "black",
            textTransform: "capitalize",
            fontSize: "16px",
            margin: "16px",
          }}
          onClick={() => navigate(-1)}
        >
          Our Coaches
        </Button>
        {/* 
        <div className="">
          {skillImageUrls.map((url, index) => (
            <Card
              elevation={0}
              style={{ width: "100%", height: "600px", borderRadius: 0 }}
            >
              <CardMedia key={index} component="img" image={url} />
            </Card>
          ))}
        </div>

        <Card>
          {!skillImageUrls.length && (
            <CardMedia component="img" image={EmptyProfile} />
          )}
        </Card> */}

        {/* <Card
          elevation={0}
          style={{ borderRadius: 0 }}
          className="profile-container"
        > */}
        {/* <CardMedia
          component="img"
          image={skillImageUrls.length !== 0 ? skillImageUrls[0] : EmptyProfile}
        /> */}

        <Box sx={{ width: "100%" }}>
          <img
            style={{
              width: "100%",
              height: "200px",
              objectFit: "cover",
            }}
            src={skillImageUrls.length !== 0 ? skillImageUrls[0] : EmptyProfile}
          />
        </Box>
        {/* </Card> */}

        <Box sx={{ padding: "28px 18px" }}>
          <Typography
            variant="h5"
            gutterBottom
            fontSize="18px"
            fontWeight={500}
          >
            {!!firstName && !!lastName
              ? firstName + " " + lastName
              : !!lastName
              ? lastName
              : firstName}
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ color: "#757575" }}
            fontSize="12px"
          >
            {!!about ? about : ""}
          </Typography>
        </Box>

        <Box>
          <SessionList tabValue={state.tabValue} coachId={id} />
        </Box>
      </div>
    </Container>
  );
}
