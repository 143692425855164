import React, { useState } from "react";
import { Navbar } from "../Navbar";
import SessionTypeList from "../SessionType/SessionTypeList";
import { Box, Container } from "@mui/material";
import CoachList from "../Coach/CoachList";
import SessionList from "../Sessions/SessionList";
import Vmax from "../../assets/VMax ShapeUp Studio Logo.png";
import { Button } from "@mui/material";

import { axiosFetch } from "../../Utils/AxiosFetch";

import "../../components/commonStyle.css";

export default function Homepage() {
  const [tabValue, setTabValue] = useState(1);

  const toggleTab = (param) => {
    setTabValue(param);
  };

  const handleSignOut = async () => {
    try {
      const response = await axiosFetch({
        url: "/leadSignout",
        method: "post",
      });

      if (response.status === 200) {
        localStorage.removeItem("authTocken");
        window.location.reload();
      }
    } catch {}
  };

  //const isAndroid = navigator.userAgent.toLowerCase().includes("android");
  const isChrome = navigator.userAgent.toLowerCase().includes("chrome");

  return (
    <Container sx={{ overflow: "hidden", padding: "0px" }}>
      <Box>
        <div
          className="header-logo-container"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <div style={{ width: "100px" }}>
            <img src={Vmax} alt="logo" style={{ width: "100%" }} />
          </div>
          {/* <div>
            {isChrome && (
              <Button
                onClick={handleSignOut}
                variant="outlined"
                color="inherit"
                sx={{
                  textTransform: "none",
                  backgroundColor: "#2d2d2d;",
                  color: "#fff",
                  // padding: "0px 10px",
                  fontSize: "12px",
                  ":hover": {
                    backgroundColor: "#2d2d2d;",
                  },
                }}
              >
                Sign out
              </Button>
            )}
          </div> */}
        </div>

        <Navbar />
      </Box>
    </Container>
  );
}
